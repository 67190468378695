import { useEffect, useRef } from "react";
import BsModal from 'bootstrap/js/dist/modal'
import { ModalService } from "../components/modal/ModalService";

export default function (show: boolean, onHidden?: () => void, onCloseRequest?: () => boolean, modalId: string = '') {
    const ref = useRef<HTMLDivElement>(null);
    const modal = useRef<BsModal>();

    useEffect(() => {
        if (ref.current && modalId === ref.current.id) {
            modal.current?.dispose();
            modal.current = new BsModal(ref.current!, {});
        }
        else if (modal.current && modalId === ref.current?.id)
            modal.current?.dispose()
    }, [ref.current]);

    useEffect(() => {
        if (modal.current && modalId === ref.current?.id) {
            if (show)
                modal.current.show();
            else {
                modal.current.hide();
                document.body.style.overflow = "";
                document.body.style.paddingRight = "";
            }
        }
    }, [show])


    useEffect(() => {
        if (ref.current && modalId === ref.current.id) {
            const element = ref.current;
            ModalService.addHiddenListener(element, onHidden);
            return () => {
                if (element && onHidden) {
                    ModalService.removeHiddenEventListener(element, onHidden);
                }
            }
        }
    }, [onHidden]);

    useEffect(() => {
        if (ref.current && modalId === ref.current.id) {
            const element = ref.current;
            const hideListener = ModalService.addHideEventListener(element, onCloseRequest);

            return () => {
                if (element && hideListener) {
                    ModalService.removeHideEventListener(element, hideListener);
                }
            }
        }
    }, [onCloseRequest]);

    return {
        divRef: ref,
    }
}