import React, { startTransition, Suspense, useEffect, useState } from 'react';
import AuthProvider from './modules/auth/context/AuthProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastProvider } from './modules/common/contexts/toastContext/ToastContext';
// import PwaModalWrapper from './modules/common/components/pwa/PwaModalWrapper';
import { MixPanelProvider } from './modules/common/contexts/mixpanelContext';
import { DeletePromptModalProvider } from './modules/common/contexts/modalContext/DeletePromptModalContext';
import { I18nProvider } from './modules/common/contexts/i18nContext/i18nContext';
import AppVersionChecker from './modules/common/components/appVersionChecker/AppVersionChecker';
import { getShowWall } from './modules/portfolio/passwordWall/utils/getShowWall';
import { PasswordWallSubscription } from './modules/portfolio/passwordWall/utils/validatePassword';
import { NotificationProvider } from './modules/portfolio/notifications/contexts/NotificationsContext';
import { MarketplaceSellerProvider } from './modules/common/contexts/marketplaceSellerContext/MarketplaceSellerContext';
// import { ShoppingCartProvider } from './modules/portfolio/events/contexts/ShoppingCartContext';

const Layout = React.lazy(() => import('./modules/common/layout/Layout'));
const PasswordWall = React.lazy(() => import('./modules/portfolio/passwordWall/components/PasswordWall'));
const ToastContainer = React.lazy(() => import('./modules/common/components/toast/ToastContainer'));

const queryClient = new QueryClient()

function App() {
    const [showWall, setShowWall] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        getShowWall().then(x => {
            startTransition(() => {
                setShowWall(x);
            })
        });

        return PasswordWallSubscription.subscribe(value => {
            startTransition(() => {
                setShowWall(value);
            })
        });

    }, []);

    return (
        <Suspense fallback={null}>
            <MixPanelProvider>
                {showWall === undefined ?
                    <></>
                    :
                    showWall ?
                        <PasswordWall />
                        :
                        <ToastProvider>

                            <QueryClientProvider client={queryClient}>
                                <AuthProvider>
                                    <I18nProvider>
                                        {/* <PwaModalWrapper> */}
                                        <DeletePromptModalProvider>
                                            {/* <ShoppingCartProvider> */}
                                            <MarketplaceSellerProvider>
                                                <NotificationProvider>
                                                    <Layout />
                                                </NotificationProvider>
                                            </MarketplaceSellerProvider>
                                            {/* </ShoppingCartProvider> */}
                                        </DeletePromptModalProvider>
                                        {/* </PwaModalWrapper> */}
                                    </I18nProvider>
                                </AuthProvider>
                            </QueryClientProvider>

                            <AppVersionChecker />
                            <ToastContainer />

                        </ToastProvider>
                }
            </MixPanelProvider>
        </Suspense>
    );
}

export default App;
